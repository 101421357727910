import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { Box, Breadcrumb, Divider, SEO, Text, Flex } from '../../../../views/components';
import books from '../../../../data/books';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle={books[14].name} seoImage={`/assets/images/${books[14].image}`} mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
        {books[14].name}
      </Text>
      <Divider mdxType="Divider" />
      <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/finance/',
        name: 'আর্থিক ব্যাপার'
      }, {
        url: '/finance/business-of-madrassa/',
        name: 'মাদরাসাতুল ইলম ফুড এন্ড বুক শপ'
      }, {
        url: '/finance/business-of-madrassa/library/',
        name: 'মাদ্রাসাস্থ পাঠাগারের বইসমূহ'
      }]} mdxType="Breadcrumb" />
      <Divider mdxType="Divider" />
      <Flex mx={[0, -2]} flexWrap="wrap" mt={3} mdxType="Flex">
        <Box width={[1, 1 / 2]} px={3} py={3} mdxType="Box">  
 <img src={`/assets/images/${books[14].image}`} alt={books[14].name} />
        </Box>
        <Box width={[1, 1 / 2]} px={3} py={3} mdxType="Box">
          <Text variant="raw" html={books[14].description} mdxType="Text" />
          <Text mdxType="Text">
মূল্য: <br /> - {books[14].prices.hardCover} <br />
            {books[14].prices.paperback ? `- ${books[14].prices.paperback}` : ''}
          </Text>
        </Box>
      </Flex>
      <Text mt={3} textAlign="center" fontWeight="bold" mdxType="Text">
Call & Whatsapp for order: 01914-723676
      </Text>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      